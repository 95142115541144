import React from 'react';
import { Dropdown } from 'react-bootstrap';

import { Container, DropdownContainer } from './styles';

interface Planos {
  ativo: string;
  categoria: string;
  idProduto: number;
  idProdutoCategoria: number;
  produto: string;
  tipoProduto: string;
  valor: string;
  valorVenda: string;
}

interface PanelDropdown {
  selectedValue: string | null;
  planos: Planos[];
  grupos: string[];
  onChange: (plano: Planos) => void;
  planoAtual: string;
}

const PanelDropdown: React.FC<PanelDropdown> = ({
  selectedValue,
  planos,
  grupos,
  onChange,
  planoAtual,
}) => {
  return (
    <Container>
      <Dropdown>
        <Dropdown.Toggle variant="primary btn-sm" className="dropdown-toggle">
          {selectedValue}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <DropdownContainer>
            {grupos.map((grupo: string) => {
              return (
                grupo !== 'Compartilhado' && (
                  <span key={grupo}>
                    <Dropdown.Header>{grupo}</Dropdown.Header>
                    {planos.map((plano: Planos) => {
                      return plano.categoria === grupo ? (
                        <Dropdown.Item
                          key={plano.idProduto}
                          disabled={planoAtual === plano.produto}
                          onClick={() => onChange(plano)}
                        >
                          {`${plano.produto} - ${plano.valor}`}
                          {plano.valorVenda === 'FALSE'
                            ? ' (com ajuste do IGP-M)'
                            : ''}
                        </Dropdown.Item>
                      ) : (
                        ''
                      );
                    })}
                  </span>
                )
              );
            })}
          </DropdownContainer>
        </Dropdown.Menu>
      </Dropdown>
    </Container>
  );
};

export default PanelDropdown;
