import produce from 'immer';
import { AuthAction } from '../auth/types';

import { SiteAction, SiteState } from './types';

type Action = SiteAction | AuthAction;

const initialState: SiteState = {
  info: {
    idSite: 0,
    idEntidadeFinanceira: 0,
    site: '',
    idPlano: 0,
    plano: '',
    tecnologia: '',
    memoria: '0',
    cpu: '',
    valor: '',
    valorVenda: true,
  },
  resources: {
    cpuPlano: '0',
    cpuServivosAdicionais: '0',
    cpuTotal: '0',
    espacoDiscoPlanoGB: 0,
    espacoDiscoServicosAdicionaisGB: 0,
    espacoDiscoTotalGB: 0,
    espacoDiscoUtilizadoGB: 0,
    espacoDiscoPorcentualUtilizado: 0,
    trafegoPlanoGB: 0,
    trafegoServicosAdicionaisGB: 0,
    trafegoTotalGB: 0,
    trafegoUtilizadoGB: 0,
    trafegoPorcentualUtilizado: 0,
    trafegoEmailMB: 0,
    trafegoEmailGB: 0,
    siteQtd: 0,
    memoriaPlano: '0',
    memoriaServivosAdicionais: '0',
    memoriaTotal: '0',
    sitesCriados: 0,
    sitesPorcentualUtilizado: 0,
    planoIlimitado: false,
    espacoEmailTotalGB: 0,
    mySqlTotalQtd: 0,
    limiteUpload: 0,
  },
  error: false,
  loading: false,
};

export default function site(state = initialState, action: Action): SiteState {
  return produce(state, draft => {
    switch (action.type) {
      case '@site/SITE_REQUEST':
        draft.loading = true;
        draft.error = false;
        break;
      case '@site/SITE_SUCCESS':
        draft.info = action.payload.info;
        draft.resources = action.payload.resources;

        draft.loading = false;
        draft.error = false;
        break;
      case '@site/SITE_FAILURE':
        draft.loading = false;
        draft.error = true;
        break;
      case '@site/SET_SELECTED_SITE_ID':
        draft.info.idSite = action.payload.idSite;
        break;
      case '@site/SET_SELECTED_SITE_NAME':
        draft.info.site = action.payload.siteName;
        break;
      case '@auth/LOGOUT':
        draft = initialState;
        break;
      default:
        return draft;
    }

    return draft;
  });
}
